import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  writeBatch,
  Unsubscribe,
} from "firebase/firestore";

export const useLessons = definePiniaStore("/lessons", () => {
  const allLessons = ref<Lessons>([]);

  const initializeLessons = async () => {
    const db = useFirestore();
    const lessonsCollectionRef = collection(db, "lessons");
    const lessonsCollectionQuery = query(
      lessonsCollectionRef,
      orderBy("displayOrder", "asc")
    );

    const subscription = ref<Unsubscribe | undefined>();

    // const snapshot = await getDocs(lessonsCollectionQuery);
    // allLessons.value = snapshot.docs.map((doc) => {
    //   return {
    //     ...doc.data(),
    //     id: doc.id,
    //   } as Lesson;
    // });

    if (subscription.value) {
      subscription.value();
    }

    subscription.value = onSnapshot(lessonsCollectionQuery, (snapshot) => {
      allLessons.value = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        } as Lesson;
      });
    });
  };

  const productCategoryStore = useIEWProductCategories();
  const { allUserIEWProductCategories } = storeToRefs(productCategoryStore);

  const allUserLessons = computed(() => {
    const appUserData = useAppUserData();
    const { availableLessonIds } = storeToRefs(appUserData);

    if (
      availableLessonIds.value != undefined &&
      availableLessonIds.value.length > 0
    ) {
      return allLessons.value.filter((lesson) => {
        return availableLessonIds.value.includes(lesson.id!);
      });
    }

    if (allUserIEWProductCategories.value.length > 0) {
      const userIEWProductCategoryIds = allUserIEWProductCategories.value.map(
        (productCategory) => {
          return productCategory.id;
        }
      );

      return allLessons.value.filter((lesson) => {
        return lesson.iewGradebookProductCategoryIds.some((id) => {
          return userIEWProductCategoryIds.includes(id);
        });
      });
    }

    return allLessons.value;
  });

  const fetchLesson = async (lessonId: string) => {
    const db = useFirestore();
    const lessonRef = doc(db, "lessons", lessonId);
    const lessonDoc = await getDoc(lessonRef);

    if (lessonDoc.exists()) {
      return {
        ...lessonDoc.data(),
        id: lessonDoc.id,
      } as Lesson;
    } else {
      return null;
    }
  };

  const saveLesson = async (lesson: Lesson) => {
    const db = useFirestore();
    if (lesson.id) {
      const lessonRef = doc(db, "lessons", lesson.id);
      await updateDoc(lessonRef, lesson);
    } else {
      const lessonsCollectionRef = collection(db, "lessons");
      await addDoc(lessonsCollectionRef, lesson);
    }
  };

  const saveLessons = async (lessons: Lessons) => {
    const db = useFirestore();
    const batch = writeBatch(db);

    for (var lesson of lessons) {
      const lessonRef = doc(db, "lessons", lesson.id!);
      batch.set(lessonRef, lesson);
    }

    await batch.commit();
  };

  return {
    allLessons,
    allUserLessons,
    initializeLessons,
    fetchLesson,
    saveLesson,
    saveLessons,
  };
});
